import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LightBlueTitle } from "../elements/titles"
import Container from "../utilities/wrapper"
import { BlueButton } from "../elements/buttons"

const ContentSection = styled.section`
  padding: 3rem 0 5rem 0;

  .fleet-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .para-y-padding {
    padding: 1rem 0;
  }

  .leftbox {
    flex-basis: 50%;

    li {
      color: #ffffff;
    }

    h4 {
      font-size: 1.2rem;
      color: #0064b0;
      padding: 0.3rem 0;
    }

    p {
      color: #ffffff;
      font-size: 1.1rem;
    }
  }
  .rightbox {
    flex-basis: 45%;
    padding-top: 1rem;
  }

  @media (max-width: 1135px) {
    flex-direction: column;
    padding: 2rem;

    .leftbox {
      flex-basis: 100%;
    }

    .rightbox {
      flex-basis: 100%;
    }
  }
`

const ScreenPrintingContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "screen-printed-shirt.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentSection>
      <Container>
        <div className="fleet-flex-wrapper">
          <div className="leftbox">
            <LightBlueTitle size="4rem" className="para-y-padding">
              Screen Printing
            </LightBlueTitle>
            <p>
              Let us help you with your screen printing needs. We are making
              custom logo teeshirts and sweatshirts affordable for those who
              need high-impact promotion and visibility for their product or
              group affiliation.
            </p>
            <p className="para-y-padding">
              We take your ideas, designs and concepts and put them on wearable
              products. Anything from shirts, caps, tote bags, aprons and many
              more items. Give us a call for quality printing and exceptional
              service.
            </p>
            <h4>Some of our screen printing specialties:</h4>
            <ul>
              <li>- Simulated Process</li>
              <li>- Index printing</li>
              <li>- Water Base and Discharge</li>
              <li>- Metallic and Glow in the dark</li>
            </ul>
            <br />
            <h4>Art and Design services available.</h4>
            <p>
              Visit our online catalogue for garment selection or call us for
              your discounted price.
            </p>
          </div>
          <div className="rightbox">
            <Img fluid={data.Image.childImageSharp.fluid} />
          </div>

          <a
            href="https://companycasuals.com/optimagraphix/start.jsp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BlueButton>Apparel Catalog</BlueButton>
          </a>
        </div>
      </Container>
    </ContentSection>
  )
}
export default ScreenPrintingContent
