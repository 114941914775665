import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LightGreenTitle } from "../elements/titles"
import Container from "../utilities/wrapper"

const ContentSection = styled.section`
  padding: 1rem 0;

  .fleet-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .para-y-padding {
    padding: 1rem 0;
  }

  .leftbox {
    flex-basis: 50%;

    p {
      color: white;
      font-size: 1.1rem;
    }
  }
  .rightbox {
    flex-basis: 50%;
    padding-top: 1rem;
  }

  @media (max-width: 1135px) {
    flex-direction: column;
    padding: 2rem;

    .leftbox {
      flex-basis: 100%;
    }

    .rightbox {
      flex-basis: 100%;
    }
  }
`

const FleetGraphicsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "FleetGraphicsPage.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentSection>
      <Container>
        <div className="fleet-flex-wrapper">
          <div className="leftbox">
            <LightGreenTitle size="4rem" className="para-y-padding">
              Fleet Graphics
            </LightGreenTitle>
            <p>
              Whether your company has one vehicle or an entire fleet, Optima
              Graphix will get the job done right and on time. Your graphics
              will feature high quality materials with extended outdoor life
              expectancy and cutting edge designs.
            </p>
            <p className="para-y-padding">
              We can help you re-create your existing logo or design a new one
              for you.
            </p>
            <p>
              We work with you and your Owner Operators. Let us take care of the
              decals, permits and unit numbers. We offer fast service and the
              best prices in town. In many cases, we go to your yard and do the
              installations on site for your convenience.
            </p>
            <br />
            <p>Give us a call today for a free quote.</p>
          </div>
          <div className="rightbox">
            <Img fluid={data.Image.childImageSharp.fluid} />
          </div>
        </div>
      </Container>
    </ContentSection>
  )
}

export default FleetGraphicsContent
