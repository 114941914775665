import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FleetGraphics from "../components/services/FleetGraphics"
import VehicleWraps from "../components/services/VehicleWraps"
import ScreenPrinting from "../components/services/ScreenPrinting"
import Embroidery from "../components/services/Embroidery"
import BannersMore from "../components/services/BannersMore"

const services = () => {
  return (
    <Layout>
      <SEO title="Optima Graphix Services" />
      <FleetGraphics />
      <VehicleWraps />
      <ScreenPrinting />
      <Embroidery />
      <BannersMore />
    </Layout>
  )
}

export default services
