import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LightBlueTitle } from "../elements/titles"
import Container from "../utilities/wrapper"

const ContentSection = styled.section`
  padding: 3rem 0 5rem 0;

  .fleet-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .para-y-padding {
    padding: 1rem 0;
  }

  .leftbox {
    flex-basis: 50%;

    p {
      color: #333333;
      font-size: 1.1rem;
    }
  }
  .rightbox {
    flex-basis: 45%;
    padding-top: 1rem;
  }

  @media (max-width: 1135px) {
    flex-direction: column-reverse;
    padding: 2rem;

    .leftbox {
      flex-basis: 100%;
    }

    .rightbox {
      flex-basis: 100%;
    }
  }
`

const VehicleWrapsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "MisterSparky.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentSection>
      <Container>
        <div className="fleet-flex-wrapper">
          <div className="rightbox">
            <Img fluid={data.Image.childImageSharp.fluid} />
          </div>
          <div className="leftbox">
            <LightBlueTitle size="4rem" className="para-y-padding">
              Vehicle Wraps
            </LightBlueTitle>
            <p>
              Vehicle wraps are a unique and cost-effective way to bring
              attention to your business. With our full wraps, you can transform
              your car, vehicle, or fleet into a billboard on wheels, attracting
              attention to your business at every turn. Considering their
              mobility, visibility, and durability, car wrap costs are extremely
              economical in comparison to traditional, short-term methods of
              advertising that must be frequently renewed at high rates.
              Investing in a custom car wrap keeps your business’s message
              broadcasting on roads and highways for years.
            </p>
            <p className="para-y-padding">
              Vehicle wraps have become a very popular way of advertising and
              without costly monthly fees compared to the cost of a billboards.
              Save money and boost your advertisement campaign. A Wrap can last
              you years and you only pay it once and you can get better results
              than a billboard.
            </p>
            <p>
              Some of our most popular services are either a full body, half or
              partial wrap. We print our vinyl in-house using High Resolution
              print and the highest quality vinyls. We also laminate our vinyl
              for longer durability, a service that most competitors don’t offer
              you.
            </p>
            <br />
            <p>
              You can also bring us your material and we’ll wrap your vehicle.
            </p>
          </div>
        </div>
      </Container>
    </ContentSection>
  )
}

export default VehicleWrapsContent
