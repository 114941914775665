import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LightBlueTitle } from "../elements/titles"
import Container from "../utilities/wrapper"
import { BlueButton } from "../elements/buttons"
import { Link } from "gatsby"

const ContentSection = styled.section`
  padding: 3rem 0 5rem 0;

  .fleet-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .para-y-padding {
    padding: 1rem 0;
  }

  .leftbox {
    flex-basis: 45%;

    li {
      color: #ffffff;
    }

    h4 {
      font-size: 1.2rem;
      color: #0064b0;
      padding: 1.3rem 0;
    }

    p {
      color: #333333;
      font-size: 1.1rem;
    }
  }
  .rightbox {
    flex-basis: 50%;
    padding-top: 1rem;
  }

  @media (max-width: 1135px) {
    flex-direction: column;
    padding: 2rem;

    .leftbox {
      flex-basis: 100%;
    }

    .leftbox h2 {
      font-size: 3.3rem;
    }

    .rightbox {
      flex-basis: 100%;
    }
  }
`

const EmbroideryContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "EmbroiderySamples.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentSection>
      <Container>
        <div className="fleet-flex-wrapper">
          <div className="leftbox">
            <LightBlueTitle size="4rem" className="para-y-padding">
              Embroidery
            </LightBlueTitle>
            <p>
              Embroidery is a classic choice for business and professional
              apparel, as well as great looking logos on team apparel, hats,
              jackets, bags, and more. With custom logo embroidery, the design
              is stitched by our automated machines on to your apparel for a
              clean and consistent result.
            </p>
            <p className="para-y-padding">
              Our in-house Art Department features talented Digitizers who will
              transform your design into exquisite stitch work. Embroidery is a
              fantastic choice for those individuals and organizations who want
              a polished and professional look. Choose from hundreds of garments
              to embroider including: polo shirts, jackets, long sleeve
              t-shirts, sweatshirts, baseball caps, towels, bags, and uniforms.
              Custom embroidered apparel is a perfect option for trade shows,
              employee uniforms, fire/ems/police departments, schools and teams,
              fundraisers, family reunions and more.
            </p>
            <br />
            <p>
              Specialty 3D puff embroidery is also available which gives your
              design a raised up look and feel for a fun twist on stitched
              logos! With our affordable embroidery prices and high quality
              standards, you’ll be loving your new custom garments.
            </p>

            <br />
            <p>
              We also offer novelty and custom patches for all biker clubs and
              associations.
            </p>
            <h4>Get your FREE quote today!</h4>
            <Link to="contact" activeClassName="active">
              <BlueButton>Contact Us</BlueButton>
            </Link>
          </div>
          <div className="rightbox">
            <Img fluid={data.Image.childImageSharp.fluid} />
          </div>
        </div>
      </Container>
    </ContentSection>
  )
}

export default EmbroideryContent
