import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import VehicleWrapsContent from "./VehicleWrapsContent"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        bgImg: file(relativePath: { eq: "wrapsbg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.bgImg.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#ffffff`}
        >
          <VehicleWrapsContent />
        </BackgroundImage>
      )
    }}
  />
)

const VehicleWraps = styled(BackgroundSection)`
  height: 100%;
  background-position: center center;
  background-size: cover;
  opacity: 1 !important;
  background-attachment: scroll;
`

export default VehicleWraps
