import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LightBlueTitle } from "../elements/titles"
import Container from "../utilities/wrapper"
const ContentSection = styled.section`
  padding: 3rem 0 5rem 0;

  .fleet-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .para-y-padding {
    padding: 1rem 0;
  }

  .leftbox {
    flex-basis: 45%;

    li {
      color: #ffffff;
    }

    h4 {
      font-size: 1.2rem;
      color: #0064b0;
      padding: 1.3rem 0;
    }

    p {
      color: #333333;
      font-size: 1.1rem;
    }
  }
  .rightbox {
    flex-basis: 50%;
    padding-top: 1rem;
  }

  @media (max-width: 1135px) {
    flex-direction: column;
    padding: 2rem;

    .leftbox {
      flex-basis: 100%;
    }

    .rightbox {
      flex-basis: 100%;
    }
  }
`

const BannersMoreContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "Banners-More.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentSection>
      <Container>
        <div className="fleet-flex-wrapper">
          <div className="leftbox">
            <LightBlueTitle size="4rem" className="para-y-padding">
              Banners & More
            </LightBlueTitle>
            <p>
              What is the best way to add a cheap promotional display to any
              budget? Our affordable vinyl banners are a cost-effective means to
              get your small business or any special event the attention it
              deserves! We offer full color banners for $3.45 sq./ft. Includes
              Hemming, Grommets and basic design. Bring us your camera ready art
              and get your banners done on time. Don’t have artwork? Just let us
              know what you have in mind and we will design the banner for you.
              Price includes basic design but we can help you with a more
              complex design for an additional small fee. Give us a call for
              more information.
            </p>

            <h4>Political Signs</h4>
            <h4>Realtor Signs</h4>
            <h4>Commercial Printing</h4>
            <br />
          </div>
          <div className="rightbox">
            <Img fluid={data.Image.childImageSharp.fluid} />
          </div>
        </div>
      </Container>
    </ContentSection>
  )
}
export default BannersMoreContent
